import { IFilterLabel, SearchMultimediaNetwork, SearchMultimediaPostType } from "@helper/search-filter";
import create from "zustand";
import { devtools } from "zustand/middleware";

interface SearchStoreState {
    searchValue: string;
    filterType: IFilterLabel;
    hiddenFilterType: IFilterLabel;
    setHiddenFilterType: (filterType: IFilterLabel) => void;
    setSearchValue: (searchValue: string) => void;
    setFilterType: (filterType: IFilterLabel) => void;
    searchSelectedMultimediaFilterNetworks: SearchMultimediaNetwork[];
    setSearchSelectedMultimediaFilterNetworks: (networks: SearchMultimediaNetwork[]) => void;
    searchSelectedMultimediaFilterPostTypes: SearchMultimediaPostType[];
    setSearchSelectedMultimediaFilterPostTypes: (postTypes: SearchMultimediaPostType[]) => void;
    toggleSearchNetwork: (network: SearchMultimediaNetwork) => void;
    toggleSearchPostType: (postType: SearchMultimediaPostType) => void;
}

const SearchStore = create<SearchStoreState>(
    devtools(
        (set) => ({
            searchValue: "",
            filterType: undefined,
            hiddenFilterType: undefined,
            setHiddenFilterType: (hiddenFilterType: IFilterLabel) => set(() => ({ hiddenFilterType })),
            setSearchValue: (searchValue: string) => set(() => ({ searchValue })),
            setFilterType: (filterType: IFilterLabel) => set(() => ({ filterType })),
            searchSelectedMultimediaFilterNetworks: [SearchMultimediaNetwork.all],
            setSearchSelectedMultimediaFilterNetworks: (networks: SearchMultimediaNetwork[]) =>
                set(() => ({ searchSelectedMultimediaFilterNetworks: networks })),
            searchSelectedMultimediaFilterPostTypes: [SearchMultimediaPostType.all],
            setSearchSelectedMultimediaFilterPostTypes: (postTypes: SearchMultimediaPostType[]) =>
                set(() => ({ searchSelectedMultimediaFilterPostTypes: postTypes })),
            toggleSearchNetwork: (network) => {
                set((state) => {
                    const currentSelectedNetworks = [...state.searchSelectedMultimediaFilterNetworks];
                    if (network === SearchMultimediaNetwork.all) {
                        state.setSearchSelectedMultimediaFilterNetworks([SearchMultimediaNetwork.all]);
                        return;
                    }
                    if (currentSelectedNetworks.includes(SearchMultimediaNetwork.all)) {
                        state.setSearchSelectedMultimediaFilterNetworks([network]);
                        return;
                    }
                    if (currentSelectedNetworks.includes(network)) {
                        const filteredNetwork = currentSelectedNetworks.filter((pt) => pt !== network);
                        if (filteredNetwork.length === 0) {
                            state.setSearchSelectedMultimediaFilterNetworks([SearchMultimediaNetwork.all]);
                            return;
                        }
                        state.setSearchSelectedMultimediaFilterNetworks(currentSelectedNetworks.filter((pt) => pt !== network));
                        return;
                    }
                    state.setSearchSelectedMultimediaFilterNetworks([...currentSelectedNetworks, network]);
                });
            },
            toggleSearchPostType: (postType) => {
                set((state) => {
                    const currentPostTypes = [...state.searchSelectedMultimediaFilterPostTypes];
                    if (postType === SearchMultimediaPostType.all) {
                        state.setSearchSelectedMultimediaFilterPostTypes([SearchMultimediaPostType.all]);
                        return;
                    }
                    if (currentPostTypes.includes(SearchMultimediaPostType.all)) {
                        state.setSearchSelectedMultimediaFilterPostTypes([postType]);
                        return;
                    }
                    if (currentPostTypes.includes(postType)) {
                        const filteredPostTypes = currentPostTypes.filter((pt) => pt !== postType);
                        if (filteredPostTypes.length === 0) {
                            state.setSearchSelectedMultimediaFilterPostTypes([SearchMultimediaPostType.all]);
                            return;
                        }
                        state.setSearchSelectedMultimediaFilterPostTypes(currentPostTypes.filter((pt) => pt !== postType));
                        return;
                    }
                    state.setSearchSelectedMultimediaFilterPostTypes([...currentPostTypes, postType]);
                });
            },
        }),
        { name: "SearchStore" },
    ),
);

export default SearchStore;
