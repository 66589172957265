import { Filter, IFilterLabel } from "@helper/search-filter";
import moment from "moment";
import React from "react";
import create from "zustand";
import { devtools } from "zustand/middleware";

export const releaseFilter: Filter = {
    name: "Release Date",
    labels: [
        {
            id: 0,
            name: "Today",
            value: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf(),
        },
        {
            id: 1,
            name: "This week",
            value: moment().subtract(1, "weeks").valueOf(),
        },
        {
            id: 2,
            name: "This month",
            value: moment().subtract(1, "month").valueOf(),
        },
        {
            id: 3,
            name: "Last 6 months",
            value: moment().subtract(6, "month").valueOf(),
        },
        {
            id: 4,
            name: "Last 12 months",
            value: moment().subtract(12, "month").valueOf(),
        },
        {
            id: 5,
            name: "All release dates",
            value: undefined,
        },
    ],
};

interface ReleaseDateState {
    releaseDateValues: IFilterLabel[];
    selectedReleaseDate: IFilterLabel;
    setSelectedReleaseDate: (label: IFilterLabel) => void;
    toggleSelectedReleaseDate: (label: IFilterLabel) => void;
    resetSelectedReleaseDate: () => void;
}

export const ReleaseDateStore = create<ReleaseDateState>(
    devtools(
        (set) => ({
            releaseDateValues: releaseFilter.labels,
            selectedReleaseDate: releaseFilter.labels[5],
            setSelectedReleaseDate: (label: IFilterLabel) => set(() => ({ selectedReleaseDate: label })),
            toggleSelectedReleaseDate: (label: IFilterLabel) =>
                set((state) => {
                    if (state.selectedReleaseDate === label) {
                        return { selectedReleaseDate: state.releaseDateValues[5] };
                    }
                    return { selectedReleaseDate: label };
                }),
            resetSelectedReleaseDate: () => set(() => ({ selectedReleaseDate: releaseFilter.labels[5] })),
        }),
        { name: "ReleaseDateStore" },
    ),
);

export const ReleaseFilter = () => {
    const releaseDateValues = ReleaseDateStore((state) => state.releaseDateValues);
    const setSelectedReleaseDate = ReleaseDateStore((state) => state.setSelectedReleaseDate);
    const selectedReleaseDate = ReleaseDateStore((state) => state.selectedReleaseDate);
    return (
        <div className="flex w-1/6 flex-col gap-4 p-2">
            <p className="border-b border-gray-600 pb-2 font-oswald text-lg font-bold uppercase">{releaseFilter.name}</p>
            <div className="grid grid-cols-1 gap-2">
                {releaseDateValues.map((releaseDate: IFilterLabel) => (
                    <div
                        className={`w-1/4 cursor-pointer hover:underline  ${
                            selectedReleaseDate === releaseDate ? "text-rc-pink" : "hover:text-rc-pink"
                        }`}
                        key={releaseDate.name}
                        onClick={() => setSelectedReleaseDate(releaseDate)}
                    >
                        <span className={`flex items-center whitespace-nowrap`}>{releaseDate.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};
