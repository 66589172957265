import { INetwork } from "@helper/networks";
import { EMultimediaFilter } from "@models/multimedia-filter";
import { IPlayerItem } from "@models/player/player-item";
import moment from "moment";
import create from "zustand";
import { devtools, persist } from "zustand/middleware";

export interface IHistoryPlayerItem {
    item: IPlayerItem;
    create_date: moment.Moment;
}
export interface IHistoryPage {
    link: string;
    title: string;
    description?: string;
    network?: INetwork;
    create_date: moment.Moment;
}

interface GlobalHistoryState {
    playerHistory: IHistoryPlayerItem[];
    pageHistory: IHistoryPage[];
    multimediaHistory: string;
    lastPage: string;
    setLastPage: (lastPage: string) => void;
    setMultimediaHistory: (multimediaHistory: string) => void;
    setPageHistory: (postHistory: IHistoryPage) => void;
    setPlayerHistory: (playerHistory: IHistoryPlayerItem) => void;
}
const GlobalHistoryStore = create<GlobalHistoryState>(
    devtools(
        persist(
            (set, get) => ({
                playerHistory: [],
                pageHistory: [],
                multimediaHistory: "videos",
                lastPage: "/social",
                setLastPage: (lastPage: string) => set(() => ({ lastPage })),
                setMultimediaHistory: (multimediaHistory: EMultimediaFilter) => set(() => ({ multimediaHistory })),
                setPageHistory: (item: IHistoryPage) =>
                    set(() => {
                        const data = get().pageHistory;
                        if (data.length > 0 && data[0].link === item.link)
                            return {
                                pageHistory: data,
                            };
                        return {
                            pageHistory: [item, ...data],
                        };
                    }),
                setPlayerHistory: (item: IHistoryPlayerItem) =>
                    set(() => {
                        const data = get().playerHistory;
                        if (data.length > 0 && data[0].item.resource_id === item.item.resource_id)
                            return {
                                playerHistory: data,
                            };
                        return {
                            playerHistory: [item, ...data],
                        };
                    }),
            }),
            {
                name: "GlobalHistoryStore",
            },
        ),
        { name: "GlobalHistoryStore" },
    ),
);

export default GlobalHistoryStore;
