import { IDecade } from "@helper/filter/release-filter-decade";
import { ISpotifyArtist } from "@helper/search/models/spotify/spotify-artist";
import { Post, PostAPIResponse, PostAuthor, UsersAPIResponse } from "@models/post";
import { SpotifyCategory } from "@models/spotify/category";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";

export const spotifyClient = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_BACKEND}/spotify`,
    withCredentials: true,
});

let isFirstRefreshRequest = true;
spotifyClient.interceptors.response.use(null, (error) => {
    if (error.config && error.response && error.response.status === 401 && isFirstRefreshRequest) {
        isFirstRefreshRequest = false;
        return axios
            .get(`${process.env.NEXT_PUBLIC_BACKEND}/spotify/refresh`, {
                withCredentials: true,
                timeout: 5000, // Set the timeout to 5000 milliseconds (5 seconds)
            })
            .then(() => {
                isFirstRefreshRequest = true;
                return axios.request(error.config);
            })
            .catch((err) => {
                if (err.message === "canceled") {
                    return;
                }
                toast.error("Failed to refresh spotify token, please try again");
                return Promise.reject(err);
            });
    }
    return Promise.reject(error);
});

export async function getSpotifyTracks(id: string) {
    const response = await spotifyClient.get<PostAPIResponse>(`/track/${id}`);

    return response.data.posts?.[0];
}

export async function getLyrics(artist: string, title: string) {
    return axios.get(`https://api.lyrics.ovh/v1/${artist}/${title}`, {}).then((e: AxiosResponse<{ lyrics: string }>) => e.data.lyrics);
}

export async function getSpotifyArtist(id: string): Promise<PostAuthor | undefined> {
    const response = await spotifyClient.get<UsersAPIResponse>(`/artist/${id}`);
    return response.data?.users?.[0];
}

// checked
export async function getNewReleasesFromSpotify(page = 1, limit = 20) {
    const response = await spotifyClient.get(`/new-releases`, {
        params: {
            // country: 'DE',
            limit,
            offset: (page - 1) * limit,
        },
    });
    return response.data as PostAPIResponse;
}

// checked
export async function getTrendingPlaylists(page = 1, limit = 20) {
    const response = await spotifyClient.get(`/playlist/trending`, {
        params: {
            country: "DE",
            limit,
            offset: (page - 1) * limit,
        },
    });
    return response.data as PostAPIResponse;
}

export async function getRecommendSpotifyRecommendation(limit = 5) {
    const response = await spotifyClient.get(`/browse/categories`, {
        params: {
            country: "DE",
            limit,
            locale: "de_DE",
        },
    });
    return response.data.categories.items as SpotifyCategory[];
}

export async function getSpotifyCategories() {
    const response = await spotifyClient.get(`/recommendations/available-genre-seeds`);
    return response.data.genres as string[];
}

export async function getAlbumsFromCategory(category: string, selectedReleaseDate: IDecade, page = 1, limit = 20) {
    const response = await spotifyClient.get<PostAPIResponse>(`/search`, {
        params: {
            q: `genre:${category} ${selectedReleaseDate.id !== -1 ? ` ${selectedReleaseDate.value}` : ""}`,
            type: "album",
            limit,
            offset: (page - 1) * limit,
        },
    });
    return response.data?.posts;
}

export async function getArtistsForCategory(category: string, page = 1, limit = 20) {
    const response = await spotifyClient.get(`/search`, {
        params: {
            q: `genre:${category}`,
            type: "artist",
            limit,
            offset: (page - 1) * limit,
        },
    });
    return response.data.artists.items as ISpotifyArtist[];
}

export async function getSpotifyCategoryPlaylists(category: string[], page = 1, limit = 20) {
    const response = await spotifyClient.get<PostAPIResponse>(`/search`, {
        params: {
            q: category.join("+"),
            type: "playlist",
            limit,
            offset: (page - 1) * limit,
        },
    });
    return response.data?.posts ?? [];
}

export async function getSpotifyShowsWithQuery(query: string, page = 1, limit = 20) {
    const response = await spotifyClient.get(`/search`, {
        params: {
            q: query,
            type: "show",
            limit,
            market: "de",
            offset: (page - 1) * limit,
        },
    });
    return response.data?.shows as PostAPIResponse;
}

export async function getSpotifyAlbumsWithQuery(query: string, page = 0, limit = 5) {
    const response = await spotifyClient.get(`/search`, {
        params: {
            q: query,
            type: "album",
            limit,
            market: "de",
            offset: page * limit,
        },
    });
    return response.data;
}

export async function getSpotifyPlaylistsWithQuery(query: string, page = 0, limit = 5) {
    const response = await axios.get(`/search`, {
        params: {
            q: query,
            type: "playlist",
            limit,
            market: "de",
            offset: page * limit,
        },
    });
    return response.data;
}

export async function getSpotifyPlaylist(id: string, page = 1, limit = 20): Promise<any | undefined> {
    const res = await spotifyClient.get(`/playlist/${id}`, {
        params: {
            limit,
            offset: (page - 1) * limit,
        },
    });
    return res.data?.posts?.[0] as PostAPIResponse;
}

export async function getSpotifyPlaylistTracks(playlistId: string, page = 1, limit = 20) {
    const response = await spotifyClient.get(`/playlists/${playlistId}/tracks`, {
        params: {
            limit,
            offset: (page - 1) * limit,
        },
    });
    return response.data;
}

export async function spotifyPlayTrack(trackId: string, device_id?: string) {
    const data = await spotifyClient.put(
        `/me/player/play`,
        {
            uris: [trackId],
        },
        {
            params: {
                device_id,
            },
        },
    );

    if (data.status === 0) {
        return true;
    }
    return false;
}

export async function getAlbumsForArtist(artistId: string, offset?: number): Promise<PostAPIResponse | undefined> {
    const result = await spotifyClient.get(`/artist/${artistId}/albums`, {
        params: {
            offset,
        },
    });

    return result.data;
}

export async function getTopTracksForArtist(artistId: string): Promise<Post[] | undefined> {
    const result = await spotifyClient.get(`/artist/${artistId}/top-tracks`, {
        params: { market: "DE" },
    });
    return result.data?.posts;
}

export async function getShowInfos(id: string) {
    const result = await spotifyClient.get(`/show/${id}`, {
        params: { market: "DE" },
    });
    return result.data;
}
export async function getEpisodeInfos(id: string) {
    const result = await spotifyClient.get(`/episode/${id}`);
    return result.data;
}
export async function handleSpotifyIsSaved(id: string, type: "albums" | "tracks" | "playlists" | "shows") {
    const data = await spotifyClient.get(`/me/save/${type}/${id}`);
    return data.data?.items.find((i) => i.id === id && i.rating === "like");
}

export async function handleSpotifyLikes(id: string, like: boolean, type: "albums" | "tracks" | "playlists" | "shows") {
    let data;
    if (like) {
        data = await spotifyClient.delete(`/me/save/${type}/${id}`);
    } else {
        data = await spotifyClient.put(`/me/save/${type}/${id}`);
    }
    return data;
}

export async function getEpisodes(id: string, limit: number, offset: number) {
    const result = await spotifyClient.get(`/show/${id}/episodes`, {
        params: {
            limit,
            offset,
        },
    });
    return result.data;
}

export async function getPodcasts(): Promise<Post[] | undefined> {
    try {
        const data = await spotifyClient.get(`/me/shows`, {});
        return data.data.posts ?? [];
    } catch (e) {
        return undefined;
    }
}

export async function searchQuerySpotify(
    q: string,
    type: "track" | "artists" | "show" | "playlist" | "album",
    market = "de",
): Promise<Record<string, PostAPIResponse> | undefined> {
    const data = await spotifyClient.get(`/search`, {
        params: { q, type, market },
    });
    return data.data;
}

export async function getAlbum(id: string): Promise<Post | undefined> {
    const data = await spotifyClient.get(`/album/${id}`, {
        params: { market: "DE" },
    });

    return data.data?.posts?.[0];
}
export async function getSpotifyToken(): Promise<string | undefined> {
    const data = await spotifyClient.get(`/me/token`);
    return data.data;
}
export async function spotifyCall(link: string): Promise<PostAPIResponse | Record<string, PostAPIResponse>> {
    const data = await spotifyClient.post(`/call`, { link });
    return data.data;
}

export async function getMySpotifyFollowingArtists(after?: string, userId = "") {
    const queryParams = [];
    if (userId) {
        queryParams.push(`userId=${userId}`);
    }
    if (after) {
        queryParams.push(`after=${after}`);
    }

    const queryString = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
    const data = await spotifyClient.get<UsersAPIResponse>(`/me/following${queryString}`);
    return data.data;
}

export async function getSpotifyPodcastFeed(limit = 2, page = 1): Promise<Record<string, PostAPIResponse> | undefined> {
    const data = await spotifyClient.get(`/me/podcasts-feed`, {
        params: {
            limit,
            page,
        },
    });
    return data.data;
}

export async function getSpotifyImages(query: string, page = 0, limit = 5) {
    const response = await spotifyClient.get(`/search`, {
        params: {
            q: query,
            type: "playlist",
            limit,
            market: "de",
            offset: page * limit,
        },
    });
    return response.data;
}
